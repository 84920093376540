import React from 'react';
import { SectionLayout } from 'components/Sections/SectionLayout';
import Table from 'components/Table';

const ContentfulTable = (props) => {
  return (
    <SectionLayout>
      <Table {...props} />
    </SectionLayout>
  );
};
export default ContentfulTable;
